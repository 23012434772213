
























































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DateTimePickerV2 from '@/app/ui/components/DateTimePickerV2/index.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import AscendingIcon from '@/app/ui/assets/ascending_icon.vue'
import Loading from '@/app/ui/components/Loading/index.vue'
import { Utils } from '@/app/infrastructures/misc'
import controller from '@/app/ui/controllers/PickupController'
import { PickupAdditionalTime } from '@/domain/entities/Pickup'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import { IOptions } from '@/data/infrastructures/misc/interfaces/pickupRadiusValidation'

import { SortFields, SortType } from '@/app/infrastructures/misc/Constants/pickup'

interface IHeaderCell {
  title: number | string
  customStyle: IStyle
}

interface IStyle {
  minWidth: string
  maxWidth: string
}

interface ITableCell {
  value: string | number | Date | string[]
  customStyle: IStyle
}

interface Parameters {
  page: number
  perPage: number
  search: string
  dateRange: {
    start: string | null
    end: string | null
  }
}

@Component({
  components: {
    TextInput,
    DateTimePickerV2,
    DataTableV2,
    AscendingIcon,
    Loading,
    PaginationNav
  },
})
export default class PickupAdditionalTimePage extends Vue {
  controller = controller

  enumSelectedSort = SortType
  enumSortFields = SortFields

  headers = [
    this.headerCellMapper('No.', '60px'),
    this.headerCellMapper('Order ID', '140px'),
    this.headerCellMapper('Tambah Waktu', '150px'),
    this.headerCellMapper('Kurir ID', '150px'),
    this.headerCellMapper('Nama Kurir', '200px'),
    this.headerCellMapper('Mulai Pick Up', '200px'),
    this.headerCellMapper('Waktu Akhir Pick Up', '220px'),
  ]

  selectedSort: SortType | null = null
  parameters: Parameters = {
    page: 1,
    perPage: 10,
    search: '',
    dateRange: {
      start: null,
      end: null,
    },
  }

  tableData: ITableCell[][] = []

  perPageOptions: IOptions[] = [
    { label: '10', value: 10 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ]

  created(): void {
    this.fetchPickups(true)
  }

  get isLastPage(): boolean {
    return (
      this.totalPages === controller.paginationData.page
    )
  }

  get totalPages(): number {
    return Math.ceil(
      Number(controller.paginationData.totalItem) /
        Number(controller.paginationData.perPage)
    )
  }

  get params(): Record<string, string | number> {
    return {
      page: this.parameters.page,
      perPage: this.parameters.perPage,
      keyword: this.parameters.search,
      startDate: this.parameters.dateRange.start
        ? Utils.formatDateWithIDLocale(
            <string>this.parameters.dateRange.start,
            'YYYY-MM-DD'
          )
        : '',
      endDate: this.parameters.dateRange.end
        ? Utils.formatDateWithIDLocale(
            <string>this.parameters.dateRange.end,
            'YYYY-MM-DD'
          )
        : '',
      sortDirection: this.selectedSort ? this.selectedSort.split('-')[1] : '',
      column: this.selectedSort ? this.selectedSort.split('-')[0] : '',
    }
  }

  get minDate(): Date {
    return new Date(new Date().setDate(new Date().getDate() - 7))
  }

  get isLoading(): boolean {
    return controller.isLoading
  }

  private fetchPickups(reset: boolean): void {
    if (reset) {
      this.parameters.page = 1
    }

    this.controller.getPickupAdditionalTime({...this.params, reset: reset})
  }

  private fetchPickupsPerPage(perPage: number): void {
    this.parameters.perPage = perPage
    this.fetchPickups(true)
  }

  private onTableSort(sortType: SortFields): void {
    switch (sortType) {
      case SortFields.COURIER_ID:
        if (this.selectedSort === SortType.COURIER_ID_ASC) {
          this.selectedSort = SortType.COURIER_ID_DESC
        } else if (this.selectedSort === SortType.COURIER_ID_DESC) {
          this.selectedSort = null
        } else {
          this.selectedSort = SortType.COURIER_ID_ASC
        }
        break

      case SortFields.COURIER_NAME:
        if (this.selectedSort === SortType.COURIER_NAME_ASC) {
          this.selectedSort = SortType.COURIER_NAME_DESC
        } else if (this.selectedSort === SortType.COURIER_NAME_DESC) {
          this.selectedSort = null
        } else {
          this.selectedSort = SortType.COURIER_NAME_ASC
        }
        break

      case SortFields.START_PICKUP:
        if (this.selectedSort === SortType.START_PICKUP_ASC) {
          this.selectedSort = SortType.START_PICKUP_DESC
        } else if (this.selectedSort === SortType.START_PICKUP_DESC) {
          this.selectedSort = null
        } else {
          this.selectedSort = SortType.START_PICKUP_ASC
        }
        break
      default:
        if (this.selectedSort === SortType.END_PICKUP_ASC) {
          this.selectedSort = SortType.END_PICKUP_DESC
        } else if (this.selectedSort === SortType.END_PICKUP_DESC) {
          this.selectedSort = null
        } else {
          this.selectedSort = SortType.END_PICKUP_ASC
        }
        break
    }

    this.fetchPickups(true)
  }

  private onSearch = Utils.debounce((search: string): void => {
    if (search.length > 2 || search.length === 0) {
      this.fetchPickups(true)
    }
  }, 500)

  private onDateRangeChange(): void {
    this.fetchPickups(true)
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string
  ): IHeaderCell {
    return {
      title: title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
      },
    }
  }

  private tableCellMapper(
    value: string | number | Date | string[],
    colWidth: string
  ): ITableCell {
    return {
      value,
      customStyle: {
        maxWidth: colWidth,
        minWidth: colWidth,
      },
    }
  }

  @Watch('controller.pickupAdditionalTimeData')
  private onPickupAdditionalTimeDataChange(
    pickup: PickupAdditionalTime[]
  ): void {
    this.tableData = pickup.map((data, index) => {
      return [
        this.tableCellMapper(
          index + 1 + this.parameters.perPage * (this.parameters.page - 1) + '.',
          '60px'
        ),
        this.tableCellMapper(data.orderId, '140px'),
        this.tableCellMapper(`${data.additionalTime} Menit`, '150px'),
        this.tableCellMapper(data.courierId, '150px'),
        this.tableCellMapper(data.courierName, '200px'),
        this.tableCellMapper(
          data.startTimePickup
            ? Utils.formatTimeZone(
                Utils.formatDateWithIDLocale(
                  data.startTimePickup,
                  'DD MMM YYYY HH:mm Z'
                )
              )
            : '-',
          '200px'
        ),
        this.tableCellMapper(
          data.endTimePickup
            ? Utils.formatTimeZone(
                Utils.formatDateWithIDLocale(
                  data.endTimePickup,
                  'DD MMM YYYY HH:mm Z'
                )
              )
            : '-',
          '220px'
        ),
      ]
    })
  }
}
